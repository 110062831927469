import * as React from "react"
import Layout from "../layouts/layout";
import RegisterForm from "../components/registerform";

const IndexPage = () => {
  return (
    <Layout pageTitle="Ja! Ik meld mij graag aan voor Wild Kids" pageType="form">
      <RegisterForm/>
    </Layout>
  )
}

export default IndexPage
