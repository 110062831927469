import React, { useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { useQueryParam, StringParam } from "use-query-params";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';

const formQuery = graphql`
{
  gcms {
    activiteiten(where: {datum_gte: now, geenDatum_not: true}, stage: PUBLISHED) {
      id
      code
      datum
      tijd
      titel
    }
  }
}
`;

const RegisterForm = () => {

  const [activity] = useQueryParam("activiteit", StringParam);
  const [childamount, setchildamount] = useState(1);

  const addChild = (event) => {
    event.preventDefault();
    if (childamount < 4) {
      setchildamount(childamount + 1);
    }
  }

  const addChildButton = <button className="addchild" onClick={addChild}>Extra kind aanmelden</button>;

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const {
    gcms: {activiteiten},
  } = useStaticQuery(formQuery);

  return (
    <Formik
      enableReinitialize={
        true
      }
      initialValues={{
        kind1Naam: '',
        kind1Geboortedatum: '',
        woonplaats: '',
        telefoonnummer: '',
        email: '',
        activiteit: `${activity}`,
        oudervoogd: false,
        betaling: '',
        textComments: '',
      }}
      validationSchema={Yup.object({
        kind1Naam: Yup.string().required('Naam is verplicht'),
        kind1Geboortedatum: Yup.string().required('Geboortedatum is verplicht'),
        woonplaats: Yup.string().required('Woonplaats is verplicht'),
        telefoonnummer: Yup.string().required('Telefoonnummer is verplicht'),        
        email: Yup.string().email('Ongeldig e-mail adres').required('E-mailadres is verplicht'),
        activiteit: Yup.string().required('Selecteer een activiteit'),
        oudervoogd: Yup.bool().oneOf([true], 'U kunt alleen kinderen aanmelden als u ouder/voogd bent'),
        betaling: Yup.string().required('Maak een keuze'),
      })}      
      onSubmit={
        (values, actions ) => {
        setTimeout(() => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "Aanmeldingen activiteiten", ...values })
          })
          .then(() => {
            navigate(
              "/bedankt",
              {
                state: {values},
              }
            )
          })
          .catch(() => {
            alert('Error');
          })
          .finally(() => actions.setSubmitting(false))
        }, 400);
      }}
    >
    {() => (
      <Form name="Aanmeldingen activiteiten" className="form register__form" data-netlify="true">
        <fieldset>
          <legend>Gegevens kind{(childamount > 1 ? "eren" : "")}</legend>
          <label htmlFor="kind1Naam" className="kind1">
            Naam {(childamount > 1 ? "kind 1" : "")}
            <span className="required"> *</span>
            <span className="error-message"><ErrorMessage name="kind1Naam"/></span>
            <Field name="kind1Naam" type="text"/>        
          </label>
          <label htmlFor="kind1Geboortedatum" className="kind1">
            Geboortedatum {(childamount > 1 ? "kind 1" : "")}
            <span className="required"> *</span>
            <span className="error-message"><ErrorMessage name="kind1Geboortedatum"/></span>
            <Field name="kind1Geboortedatum" type="text"/>        
          </label>
          <label htmlFor="kind2Naam" className={"kind2 " + (childamount > 1 ? "visible" : "hidden")}>
            Naam kind 2
            <Field name="kind2Naam" type="text"/>        
          </label>
          <label htmlFor="kind2Geboortedatum" className={"kind2 " + (childamount > 1 ? "visible" : "hidden")}>
            Geboortedatum kind 2
            <Field name="kind2Geboortedatum" type="text"/>        
          </label>
          <label htmlFor="kind3Naam" className={"kind3 " + (childamount > 2 ? "visible" : "hidden")}>
            Naam kind 3
            <Field name="kind3Naam" type="text"/>        
          </label>
          <label htmlFor="kind3Geboortedatum" className={"kind3 " + (childamount > 2 ? "visible" : "hidden")}>
            Geboortedatum kind 3
            <Field name="kind3Geboortedatum" type="text"/>        
          </label>
          <label htmlFor="kind4Naam" className={"kind4 " + (childamount > 3 ? "visible" : "hidden")}>
            Naam kind 4
            <Field name="kind4Naam" type="text"/>        
          </label>
          <label htmlFor="kind4Geboortedatum" className={"kind4 " + (childamount > 3 ? "visible" : "hidden")}>
            Geboortedatum kind 4
            <Field name="kind4Geboortedatum" type="text"/>        
          </label>
          {(childamount < 4 ? addChildButton : "")}                                  
        </fieldset>

        <fieldset>
          <legend>Contactgegevens</legend>
          <label htmlFor="woonplaats">
            Woonplaats
            <span className="required"> *</span>
            <span className="error-message"><ErrorMessage name="woonplaats"/></span>
            <Field name="woonplaats" type="text"/>   
          </label>
          <label htmlFor="telefoonnummer">
            Telefoonnummer
            <span className="required"> *</span>
            <span className="error-message"><ErrorMessage name="telefoonnummer"/></span>
            <Field name="telefoonnummer" type="text"/>   
          </label>     
          <label htmlFor="email">
            E-mailadres
            <span className="required"> *</span>
            <span className="error-message"><ErrorMessage name="email"/></span>
            <Field name="email" type="email"/>  
          </label>
        </fieldset>

        <fieldset>
          <legend>Activiteit</legend>
          <label htmlFor="activiteit">Kies 1 van de activiteiten
            <span className="required">*</span>
            <span className="error-message"><ErrorMessage name="activiteit"/></span>
            <Field as="select" name="activiteit">
              <option value=''>Selecteer een activiteit</option>
              {activiteiten.map(({id, ...activiteit}) => (
              <option key={id} value={activiteit.code}>
                {new Intl.DateTimeFormat("nl-NL", {day: "2-digit"}).format(new Date(activiteit.datum))} {new Intl.DateTimeFormat("nl-NL", {month: "long"}).format(new Date(activiteit.datum))} ({activiteit.tijd}) - {activiteit.titel}
              </option>
            ))}
            </Field>
          </label>
        </fieldset>

        <fieldset>
          <legend>Toestemming *</legend>
          <ul>
            <li>
              <label htmlFor="oudervoogd">
                <span className="error-message"><ErrorMessage name="oudervoogd"/></span>
                <div className="option">
                <Field name="oudervoogd" type="checkbox" value="Ja"/> 
                  Ja, ik ben ouder/voogd van het aangemelde kind / kinderen
                </div>
              </label>
            </li>
          </ul>
        </fieldset>             

        <fieldset>
          <legend id='betaling'>Betaling <span className="required">*</span></legend>
          <ul aria-labelledby='betaling' role='group'>
            <li>
              <label>
                <span className="error-message"><ErrorMessage name="betaling"/></span>
                <div className="option">
                  <Field id="betalingsverplichting" type="radio" name="betaling" value="betalingsverplichting" />
                  Ja, ik ben mij bewust dat ik door deze aanmelding een betalingsverplichting aan ga
                </div>
              </label>
            </li>
            <li>
              <label>
                <div className="option">
                  <Field id="wildbuddyprogram" type="radio" name="betaling" value="wildbuddyprogram" />
                  Ja, ik wil graag aanspraak maken op het Wild Buddy Program, voor kosteloze deelname van dit kind / deze kinderen
                </div>
              </label>
            </li>
          </ul>
        </fieldset>

        <fieldset>
          <label htmlFor='Opmerking'>
            Opmerking
            <Field name="Opmerking" component="textarea"/>
          </label>
        </fieldset>

        <button type="submit">Aanmelden</button>
      </Form>
    )}
    </Formik>
  )
}

export default RegisterForm